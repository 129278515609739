/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'

import App from './views/App.vue'
import Router from 'vue-router'
import vueChocolat from 'vue-chocolat'
import Tabs from "vue-material-tabs";
import VeeValidate from 'vee-validate';
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();


Vue.use(Router)
Vue.component('vue-chocolat', vueChocolat)
Vue.use(Tabs);
Vue.use(VeeValidate);
const dict = {
  custom: {
    name: {
      required: () => 'Este campo nombre es obligatorio',
    },
    email: {
      required: () => 'El campo mail es obligatorio',
      email: () =>'El formato de email es incorrecto'
    },
    message: {
      required: () => 'Este campo mensaje es obligatorio',
    }
  }
};
VeeValidate.Validator.localize('en', dict);


const router =  new Router({
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });
    } 
    else {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
    // else if (savedPosition) {
    //   return savedPosition
    // } 
    // else {
    //   return { x: 0, y: 0 }
    // }
  },
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
        path: "/",
        name: "Index",
        component: () => import("./views/Index"),
        },
        {
          name: "Padrillos",
          path: "/padrillos",
          component: () => import("./views/sections/Padrillos"),
        }, 
        {
          name: "Donantes",
          path: "/donantes",
          component: () => import("./views/sections/Donantes"),
        },
    ]
  });


const app = new Vue({
    el: '#app',
    router,
    components: { App }
});

